<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
               @submit.native.prevent>
        <el-form-item label="省:" label-width="35px" class="w-170">
          <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省'
                     clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
                     placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="58px" class="w-200 mr-20">
          <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                       :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备ID:" label-width="62px" class="w-200">
          <el-input v-model="table.where.om_id" placeholder="请输入设备ID" clearable/>
        </el-form-item>
        <el-form-item label="开始时间：" label-width="110px">
          <el-date-picker :picker-options="pickerOptionsStart" style="width:300px" popper-class="no-atTheMoment"
                          @change="birthdayChange" v-model="tableCreate_time" value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" label-width="110px">
          <el-date-picker :picker-options="pickerOptionsEnd" style="width:300px" popper-class="no-atTheMoment"
                          @change="birthdayChange1" v-model="tableend_time" value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="订单编号:" label-width="80px" class="w-200">
          <el-input v-model="table.where.order_id" placeholder="请输入订单编号" clearable style="width: 200px"/>
        </el-form-item>
        <el-form-item style="margin-left: 100px">
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                     class="ele-btn-icon">搜索
          </el-button>
          <el-button @click="reset_search()">重置</el-button>
        </el-form-item>

      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
                           show-overflow-tooltip/>
          <el-table-column prop="address" label="地区" show-overflow-tooltip min-width="220"/>
          <el-table-column prop="om_id" label="设备ID" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="order_id" label="订单编号" show-overflow-tooltip min-width="220"/>
          <el-table-column prop="duration" label="录音文件时长(秒)" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="ctime" label="下单时间" show-overflow-tooltip min-width="220"/>
          <el-table-column prop="recording_remote" label="OSS文件存储路径" show-overflow-tooltip min-width="220"/>
          <el-table-column label="操作" width="180px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="bofang(row)" icon="el-icon-view" type="primary" :underline="false">播放录音</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>

  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

export default {
  name: "SysUser",
  components: {},
  data() {
    return {
      table: {
        url: '/omapiReport/index',
        where: {}
      },

      changePro: false,
      jd_changePro: false,
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      dialogVisiblePlans: false,
      blackReason: {},
      editForm: {}, // 表单数据
      provArr: [],
      cityArr: [],
      districtArr: [],
      jd_provArr: [],
      jd_cityArr: [],
      jd_districtArr: [],
      checkForm: {},
      recordsList: [],
      startTime: '',
      endTime: '',
      driverData: {},
      dialogTableVisibleEdit: false,
      dialogTableVisibleCheck: false,
      dialogTableVisibleRecords: false,
      dialogTableVisibleCarRecords: false,
      pageSize: 10,
      pageNumber: 1,
      recordsTotal: 1000,
      recordsShow: false,
      recordsId: '',
      loading: true,
      pid: '',
      cid: '',
      aid: '',
      tablestart_time: '',
      tableCreate_time: '', //搜索选中时间戳
      tableend_time: '',
      time: [],
      player: '',
      pickerOptionsStart: {
        disabledDate: time => {
          let endDateVal = this.tableend_time;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          let beginDateVal = this.tableCreate_time;
          if (beginDateVal) {
            return (
                time.getTime() <
                new Date(beginDateVal).getTime()
            );
          }
        }
      },
    }
  },
  created() {
    this.$http.get('/common/province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
      this.jd_provArr = data
    })

  },
  beforeRouteLeave(to, form, next) {
    next()
    //console.log('离开了页面')
    this.player.pause();
    this.player = ''
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {
    birthdayChange() {
      //console.log(this.tableCreate_time)
      this.time[0] = this.tableCreate_time
      this.table.where.create_time = this.time;
    },
    birthdayChange1() {

      this.time[1] = this.tableend_time
      this.table.where.create_time = this.time;
      //console.log(this.table.where.create_time)

    },

    /**
     *选择省
     **/
    handleChangeProv(e) {
      /** 获取被选省省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      } else {
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },
    bofang(row) {
      if (this.player) {
        this.player.pause();
      }
      this.player = new Audio(row.cut_audio);
      this.player.currentTime = 1;
      this.player.play();
    },

    //重置
    reset_search() {
      this.table.where = {}
      this.tableCreate_time = '';
      this.tableend_time = '';
      //console.log(this.tableCreate_time, this.tableend_time, 'this.tableend_time')
      this.$refs.table.reload()
      //console.log(this.tableCreate_time)
      //console.log(this.tableend_time, 'this.tableend_time')
    },

  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}

/deep/ .el-dialog {
  margin-top: 40px !important;
}
</style>
